export function isMobile() {
  return window.innerWidth <= 768; // 通常小于768px视为移动设备
}

// 使用示例
if (isMobile()) {
  console.log("正在使用移动设备");
} else {
  console.log("不是移动设备");
}
