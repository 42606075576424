<template>
  <div class="title-container">
    <img src="../assets/images/logo.png" alt="" />
    <span>{{ props.title }}</span>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
const props = defineProps(["title"]);
</script>

<style scoped>
.title-container {
  display: flex;
  align-items: center;
  font-weight: 800;
  margin: 0;
}

.title-container span {
  font-size: 36rem;
  color: #304faa;
}

.title-container img {
  width: 80rem;
  height: 80rem;
}

@media only screen and (max-width: 768px) {
  .title-container span {
    font-size: 20rem;
  }
  .title-container img {
    width: 40rem;
    height: 40rem;
  }
}
</style>
