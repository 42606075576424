<template>
  <header class="header-container">
    <div class="nav">
      <div class="logo">
        <img src="../assets/images/logo.png" alt="" />
        <span>陆兮科技LuxiTech</span>
      </div>
      <ul>
        <li
          v-for="(item, i) in navList"
          :key="item.id"
          :class="{ active: i == 0 }"
        >
          <a :href="item.href" @click.prevent="scrollToAnchor(item.id)">
            {{ item.text }}</a
          >
        </li>
        <!-- <li class="active"><a href="#">首页</a></li>
        <li><a href="#jj" @click.prevent="scrollToAnchor('jj')">简介</a></li>
        <li><a href="#lnjg">类脑架构</a></li>
        <li><a href="#hymx">行业模型</a></li>
        <li><a href="#dcmx">端侧模型</a></li>
        <li><a href="#cstd">创始人简介</a></li>
        <li>
          <a href="#con" @click.prevent="scrollToAnchor('con')">联系方式</a>
        </li> -->
      </ul>
    </div>
  </header>
</template>

<script setup>
const navList = [
  {
    href: "#",
    id: "home",
    text: "首页",
  },
  {
    href: "#jj",
    id: "jj",
    text: "简介",
  },
  {
    href: "#lnjg",
    id: "lnjg",
    text: "类脑架构",
  },
  {
    href: "#hymx",
    id: "hymx",
    text: "行业模型",
  },
  {
    href: "#dcmx",
    id: "dcmx",
    text: "端侧模型",
  },
  {
    href: "#cstd",
    id: "cstd",
    text: "创始人简介",
  },
  {
    href: "#con",
    id: "con",
    text: "联系方式",
  },
];
const scrollToAnchor = (anchorId) => {
  const element = document.getElementById(anchorId);
  if (element) {
    // 使用scrollIntoView函数实现平滑滚动
    element.scrollIntoView({ behavior: "smooth" });
  }
};
</script>

<style>
.header-container {
  width: 100%;
  height: 60rem;
  background: #222222;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  font-size: 16rem;
}

.nav {
  margin: 0 auto;
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #9d9d9d;
}

.nav .logo {
  display: flex;
  font-size: 18rem;
  align-items: center;
}

.nav .logo img {
  width: 50rem;
  height: 50rem;
}

.nav ul {
  display: flex;
  height: 100%;
  align-items: center;
}

.nav ul li.active {
  background: #333;
}

.nav ul li.active a {
  color: #fff;
}
.nav ul li {
  padding: 0 15rem;
  height: 100%;
}

.nav ul li a {
  display: block;
  height: 100%;
  color: #9d9d9d;
  line-height: 60rem;
}

@media only screen and (max-width: 768px) {
  .header-container {
    display: flex;
  }
  .nav {
    width: 100%;
  }
  .nav ul {
    display: none;
  }
  /* .header-container {
    font-size: 20rem;
    height: 140rem;
  }

  .nav .logo {
    font-size: 54rem;
  }

  .nav .logo img {
    width: 100rem;
    height: 100rem;
  } */
}
</style>
