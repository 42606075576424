import { createApp } from "vue";
import "normalize.css";
import "./main.css";
import App from "./App.vue";
import { isMobile } from "./helper";
import _ from "lodash";

//我这里是根据ua来区分移动端和pc端,您可以选择适合自己的方案
export function setDomFontSizeByUa() {
  // const isMobile = navigator.userAgentData.mobile;
  const isMobileDev = isMobile();
  console.log(isMobileDev);
  let fontsize = "";
  let width = document.documentElement.clientWidth || document.body.clientWidth;
  console.log(isMobileDev);
  if (isMobileDev) {
    fontsize = width / 375 + "px"; //重点在这,375是移动端设计稿的宽度
  } else {
    fontsize = width / 1920 + "px"; //重点在这,1920是pc端设计稿的宽度
  }
  console.log(width, fontsize);
  document.getElementsByTagName("html")[0].style["font-size"] = fontsize;
}

let setDomFontSizeDebounce = _.debounce(setDomFontSizeByUa, 400); //做个防抖
window.addEventListener("resize", setDomFontSizeDebounce);

setDomFontSizeByUa();

createApp(App).mount("#app");
