<template>
  <div class="c2">
    <img v-if="turn" class="left-image" :src="props.imageUrl" alt="" />
    <div class="c2-left">
      <TitleComp :title="props.title" />
      <slot></slot>
    </div>
    <img v-if="!turn" class="right-image" :src="props.imageUrl" alt="" />
    <!-- <img src="../assets/images/xp.png" alt="" /> -->
  </div>
</template>

<script setup>
import TitleComp from "./TitleComp.vue";
import { defineProps } from "vue";

const props = defineProps({
  imageUrl: {
    type: String,
    default: "",
  },
  turn: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: "",
  },
});
</script>

<style scoped>
.c2 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30rem 0;
  background: #f6f8fc;
}

.c2-left {
  display: flex;
  flex-direction: column;
  padding: 0 20rem;
}
.c2 img {
  width: 250rem;
  transform: translateY(20%);
}

.c2 .left-image {
  margin-right: 50rem;
}

.c2 .right-image {
  margin-left: 50rem;
}

@media only screen and (max-width: 768px) {
  .c2 {
    justify-content: left;
  }
  .c2 img {
    display: none;
  }
}
</style>
