<template>
  <div id="home" class="container">
    <!-- <div class="left">
      <div class="left-title">
        <img src="../assets/images/logo.png" alt="" />
        <img src="../assets/images/LuxiTech.png" alt="" />
      </div>
      <img class="left-name" src="../assets/images/lxkj.png" alt="" />
      <span>中国首个类脑大模型架构企业</span>
    </div>
    <img class="cx" src="../assets/images/cx.png" alt="" /> -->
    <video
      width="100%"
      height="100%"
      id="my_video"
      @play="play"
      autoplay
      muted
      playsinline
    >
      <source src="../assets/video.mp4" type="video/mp4" />
    </video>
  </div>
</template>

<script setup>
const play = () => {
  const my_video = document.querySelector("#my_video");
  my_video.addEventListener("timeupdate", () => {
    if (my_video.currentTime > 4) {
      my_video.pause();
    }
  });
};
</script>

<style>
video::-webkit-media-controls-timeline {
  display: none;
}
video::-webkit-media-controls-enclosure {
  display: none;
}
.container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: calc(100vh - 60rem);
}

.left {
  display: flex;
  flex-direction: column;
  margin-right: 60rem;
}

.left-title {
  display: flex;
  align-items: center;
  margin-bottom: 20rem;
}

.left-title img:first-child {
  width: 60rem;
  height: 60rem;
}

.left-title img:last-child {
  height: 40rem;
}

.left-name {
  height: 80rem;
  margin-bottom: 20rem;
}

.left span {
  font-size: 24rem;
  color: #3551a4;
  letter-spacing: 9.6rem;
}

.cx {
  width: 240rem;
}

@media only screen and (max-width: 768px) {
  video::-webkit-media-controls-panel {
    display: none !important;
  }
  video::-webkit-media-controls {
    display: none !important;
  }
  .container {
    height: 30vh;
  }
  .left-title img:first-child {
    width: 30rem;
    height: 30rem;
  }

  .left-title img:last-child {
    height: 20rem;
  }

  .left-name {
    height: 40rem;
    margin-bottom: 10rem;
  }

  .left {
    margin: 0;
  }

  .left span {
    font-size: 12rem;
    letter-spacing: 4rem;
  }
  .cx {
    display: none;
  }
}
</style>
